
    @charset "UTF-8"; @use "sass:math"; @import "/src/_core/scss/core.scss"; @import "/src/global/scss/_abstracts.scss"; 
    @media print {

    body {
        font-size: 16px;
        color: black;
        background-color: white;
        margin: 0;
        * {
            background-color: transparent !important;
            color: inherit !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }
    }
    .btn {
        border: 1px solid black !important;
    }
    .alert,
    .breadcrumbs,
    .cookie-notice,
    app-list-tools,
    .pagination,
    app-list-toggle,
    video,
    audio,
    form,
    iframe[src*='youtube'],
    iframe[src*='vimeo'],
    iframe[src*='google']{
        display: none !important;
    }
    .header {
        &__util,
        &__toggle,
        &__nav,
        &__drawer,
        &__tools {
            display: none!important;
        }
    }
    @each $name,
    $color in $fills {
        .composition--fill-#{'' + $name} {
            background: none !important;
            &:before {
                background: none !important;
            }
        }
    }
    .composition {
        padding: 0 !important;
    }
    .composition--spaced {
        padding:20px 0 !important;
        margin:0 !important;
    }
    section {
        margin-bottom:1rem !important;
    }
    .section {
        &__inner {
            display: flex!important;
            flex-direction: column!important;
        }
    }
    .hero-banner {
        background: none !important;
        min-height: unset !important;
        padding-top:20px !important;
        padding-bottom:20px !important;
        &__inner {
            display: flex;
            flex-direction: column;
        }
        &__media,
        &__background {
            display: none !important;
        }
        &__content {
            margin:0 !important;
        }
        &__list {
            margin-bottom:0 !important;
            li {
                font-size:14px !important;
                margin-bottom:8px !important;
            }
        }
    }
    .feature {
        display: flex !important;
        flex-direction: column !important;
        transform: none !important;
        &__media {
            display: block !important;
            transform: none!important;
        }
        &__play {
            display: none !important; 
        }
        &__content {
            padding: 1cm 0!important;
            transform: none!important;
        }
    }
    .feature-banner {
        &__media {
            display: none !important;
        }
    }
    .accordion {
        gap:4px !important;

        &__content {
            display: block !important;
            visibility: visible!important;
            height: auto !important;
            opacity: 1!important;
        }
        &__content-inner {
            padding:4px 0 0 0 !important;

            p:last-child {
                margin-bottom:0 !important;
            }
        }
        &__trigger {
            padding:0 !important;
            font-size:16px !important;
            &:before,
            &:after {
                display: none;
            }
        }
        &__item {
            padding-top:8px !important;
        }
        .event-item p {
            font-size:14px !important;
        }
    }
    .app-title {
        &__background {
            display: none !important;
        }
    }
    .video {
        &__play {
            display: none !important;
        }
    }
    .footer__contact {
        display:flex!important;
        flex-direction:column !important;
    }
    .on-page-nav__nav {
        border:none !important;
    }
    .on-page-nav__inner.on-page-nav__inner.on-page-nav__inner {min-height:unset!important;height:auto!important;}
    .on-page-nav__link {
        font-size:13px !important;
        padding:0 !important;
        line-height:1 !important;
        height:auto !important;
    }
    .on-page-nav__next,
    .on-page-nav__prev {
        display:none;
    }
    .tabs {
        &--event {
            .accordion__item {
                break-after: avoid;
            }
            .event__speakers + * {
                break-before: avoid;
            }
        }
        &__tabpanel {
            margin:0 !important;
        }
        &__nav {
            border:none !important;
        }
        &__nav-inner {
            padding:0 !important;
            margin:0 !important;
            overflow:visible !important;
            max-width: unset !important;
        }
        &__tab,
        .js-print {
            display: none;
        }
        &__tab[aria-selected="true"] {
            display:block !important;
            padding:0 !important;
            margin:0 0 24px 0 !important;
            font-size:19px !important;
            text-align:left;
            font-family: $fontFamilyHeadings !important;
        }
    }
    .h5.event__time {
        font-size:18px !important;
        font-weight:bold !important;
    }
    .grid {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        &__item {
            break-inside: avoid;
        }
    }
    .dialog {
        &__content {
            background-color: white !important;
        }
    }
    .media-object {
        display: flex !important;
        flex-direction: row !important;
    }
    .search {
        display: block !important;
        &__layout {
            display: flex !important;
            flex-direction: column !important;
        }
        &__rail,
        &__footer {
            display: none !important;
        }
        &-result {
            break-inside: avoid;
        }
    }
    .footer {
        &__inner {
            display: flex!important;
            flex-direction: column!important;
            align-items: center;
            justify-content: space-between;
            background: none;
        }
        &__column:not(.footer__column--social) {
            display: none;
        }
        &__column--social {
            .footer__link--social {
                &:after {
                    content: " (" attr(href) ")";
                    font-size: .73em;
                }
            }
        }
        &__nav {
            display: block;
        }
        &__title {
            display: none;
        }
        &__social {
            justify-content: center;
            align-items: center;
            display: flex;

            .footer__link {
                font-size:13px !important;
            }
        }
        &__list,
        &__logo {
            display: none;
        }
        &__main {
            display:none;
        }
        &__legal {
            display:none !important;
        }
    }
}
  